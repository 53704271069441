import React, { Component, Fragment } from 'react'
import NewUser from './NewUser.js'

export default class Modals extends Component {
    render() {
        return (
            <Fragment>
                <NewUser/>
            </Fragment>
        )
    }
}
